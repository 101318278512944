<template>
  <!-- create dialog using vuetify for data entry -->
  <v-dialog width="80%" :value="value" @input="$emit('input', $event)">
    <v-form v-model="valid" ref="form" @submit.prevent="submit" lazy-validation
    >
      <v-card>
        <v-card-title class="d-flex justify-center">
          <h2>
            {{ $t("hr.variation.title") }}
          </h2>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-col md="4">
                <v-text-field :disabled="readMode" :rules="requiredRule()" :label="$t('hr.time.date')" type="date" outlined
                              v-model="form.document.date">
                </v-text-field>
              </v-col>
              <v-col md="4">
                <v-text-field :disabled="readMode"  :label="$t('hr.vacation.note')" outlined v-model="form.document.note">
                </v-text-field>
              </v-col>
            </v-row>
            <template v-for="(emp, idx) in form.employees">
              <v-row class="no-padding">
                <v-col>
                  <v-select :items="employeesContracts" item-text="name" item-value="id" :disabled="readMode" :rules="requiredRule()" :label="$t('hr.time.emp-name')"
                                outlined v-model="form.employees[idx].employee_contract_id">
                  </v-select>
                </v-col>
                <v-col>
                  <v-select  :items="variations" item-text="name" item-value="id" :disabled="readMode" :rules="requiredRule()" :label="$t('hr.variation.type')" outlined
                                v-model="form.employees[idx].bonus_id" @input="chooseValue(form.employees[idx].bonus_id,idx)">
                  </v-select>
                </v-col>
                <v-col>
                  <v-text-field :disabled="readMode" :rules="requiredRule()" :label="$t('hr.variation.value')" outlined
                                v-model="form.employees[idx].value">
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :disabled="readMode" :rules="requiredRule()" :label="$t('date')" type="date" outlined
                                v-model="form.employees[idx].date">
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :disabled="readMode" :label="$t('hr.vacation.note')" outlined
                                v-model="form.employees[idx].note">
                  </v-text-field>
                </v-col>
              </v-row>
              <br>
              <v-divider></v-divider>
              <br>
            </template>
            <v-btn type="button"
                   @click="addAnotherEmployee">{{ $t('add') }}
            </v-btn>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="green"
            class="white--text"
            depressed
            type="submit"
          >{{ $t("submit") }}
          </v-btn>
          <v-btn
            color="red"
            class="white--text"
            depressed
            @click="$emit('input', false)"
          >{{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

  </v-dialog>
</template>

<script>
import {requiredRule} from "@/helpers/validation-rules";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'VariationModal',
  data() {
    return {
      valid: false,
      form: {
        document: {
          name: 'variation',
          date: '',
          note: '',
        },
        employees: [
          {
            date: '',
            bonus_id: '',
            employee_contract_id: '',
            value: '',
            note: ''
          }
        ],
      }
    }
  },
  mounted() {
    this.fetchVariations()
    this.fetchEmplCont()
  },
  methods: {
    ...mapActions({
      store: 'variations/store',
      update: 'variations/update',
      fetchVariations: 'employees/fetchVariations',
      fetchEmplCont: 'employees/fetchEmployeesContracts',
    }),
    addAnotherEmployee() {
      this.form.employees.push({
        date: '',
        bonus_id: '',
        employee_contract_id: '',
        value: '',
        note: ''
      })
    },
    requiredRule() {
      return requiredRule
    },
    async submit() {
      const valid = await this.$refs.form.validate()
      if (valid) {
        await this[this.item ? 'update': 'store'](this.form)
        this.$emit('input', false)
      }
    },
    chooseValue(id,idx){
      
      for (let i = 0; i < this.variations.length; i++){
         if(this.variations[i].id==id)
           this.form.employees[idx].value=this.variations[i].value
      }
    }
  },
  props: {
    readMode: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null
    }

  },
  computed: {
    ...mapGetters({
      employeesContracts: 'employees/employeesContracts',
      variations: 'employees/variations'
    })
  },
  watch: {
    item(nV) {
      this.form = {...this.form, ...nV, document: {...nV}}
    }
  }

}
</script>

<style scoped>
.no-padding >>> div.col {
  padding-left: 1px !important;
  padding-right: 1px !important;
}
</style>
